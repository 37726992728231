<template>
  <div>
    <breadcrumb-bar
      :title="appTitle"
      :breadcrumbs="breadcrumbs"
    />
    <v-container
      fluid
    />
    <error-dialog
      :dialog="showErrorDialog"
      :title="$t('errorTitleProcess')"
      :message="errorMessage"
      @update:dialog="showErrorDialog = false"
    />
    <process-overlay
      :processing="modelProcessing"
    />
  </div>
</template>
<script>
  export default {
    name: 'Profile',
    components: {
      BreadcrumbBar: () => import('@/views/components/core/BreadcrumbBar'),
      ErrorDialog: () => import('@/views/components/core/ErrorDialog'),
      ProcessOverlay: () => import('@/views/components/core/Overlay'),
    },
    data: function () {
      return {
        appTitle: this.$t('titleProfile'),
        breadcrumbs: [
          {
            text: this.$t('bcHome'),
            disabled: false,
            href: '/dashboard',
          },
          {
            text: this.$t('bcProfile'),
            disabled: true,
            href: '/myprofile',
          },
        ],
        showErrorDialog: false,
        errorMessage: '',
        environment: process.env.VUE_APP_ENVIRONMENT,
        modelProcessing: false,
      }
    },
  }
</script>
